<section class="core" *ngIf="showLoginstep">
    <div class=" wrapper">
        <!--
        <div class=" tabs ">
            <ul>
                <li class="sign_in_li active " (click)="showLoginSignUpSteps(1) ">LOGIN</li>
                <li class="devider "></li>
                <li class="sign_up_li " (click)="showLoginSignUpSteps(2) ">SIGN UP</li>
            </ul>
        </div>-->
        <div class="container ">
            <div class="sign_in " *ngIf="showLogin ">
                <div class="logo-container ">
                    <img src="../../assets/reflow-newlogo.png " class="logo" />
                </div>
                <hr>
                <p class="header-text margin-text ">Sign in to your account</p>
                <p class="login-failed" *ngIf="loginFailed ">* Incorrect email or password.</p>
                <form [formGroup]="loginFormGroup">
                    <div class="input_field inputWithIcon input_field_login">
                        <input type="text " placeholder="E-mail " class="input " formControlName="EmailAddress">
                        <i class="icon-email icons" aria-hidden="true "></i>
                    </div>
                    <div class="input_field inputWithIcon input_field_login">
                        <input type="{{fieldType}}" placeholder="Password" class="input " formControlName="Password">
                        <i class="icon-lock icons" aria-hidden="true "></i>
                        <i class="{{iconType}} right-icon " aria-hidden="true " (click)="showPasswordText(1)"></i>
                    </div>
                    <a href="https://www.re-flow.io/contact-us/" class="help-text ">Do you need help?</a>
                    <p class="help-text text-right " (click)="showResetPasswordSteps(1) ">Forgot password?</p>
                    <div class="center-container "><button type="button " class="btn-login " (click)="loginUser()">Login</button></div>
                </form>
            </div>
            <div class="forgot_password forgot_password1 " *ngIf="showLogin2 ">
                <div class="logo-container ">
                    <i class="icon-back_arrow back-icon top-arrow" aria-hidden="true " (click)="showLoginSignUpSteps(1) "></i>
                    <img src="../../assets/reflow-newlogo.png " class="logo " />
                </div>
                <hr>
                <p class="header-text margin-top-text">Verify your email address</p>
                <p class="blue-text sub-header-text">We have sent a code to your email. Enter the code below to confirm your email address</p>
                <p class="login-failed" *ngIf="showIncorrectCode">* Incorrect code.</p>
                <div class="input_field inputWithIcon ">
                    <input #verificationCode type="text " placeholder="Enter your code here" class="input ">
                    <i class="icon-lock icons" aria-hidden="true "></i>
                </div>
                <p class="help-text " (click)="sendcode()">Haven’t received a code?</p>
                <div class="center-container "><button type="button " class="btn-login" (click)="verifyCode(verificationCode)">Next</button></div>
            </div>
            <div class="forgot_password forgot_password1 " *ngIf="showResetPwd1 ">
                <div class="logo-container ">
                    <i class="icon-back_arrow back-icon top-arrow" aria-hidden="true " (click)="showLoginSignUpSteps(1) "></i>
                    <img src="../../assets/reflow-newlogo.png " class="logo " />
                </div>
                <hr>
                <p class="header-text margin-top-text">Reset your password</p>
                <p class="blue-text sub-header-text">We need your email address to find your account.</p>
                <p class="login-failed" *ngIf="false">* Incorrect email</p>
                <div class="input_field inputWithIcon ">
                    <input type="text " placeholder="E-mail " class="input " [(ngModel)]="emailforreset">
                    <i class="icon-email icons" aria-hidden="true "></i>
                </div>
                <a href="https://www.re-flow.io/contact-us/" class="help-text ">Do you need help?</a>
                <div class="center-container "><button type="button " class="btn-login " (click)="showResetPasswordSteps(2) ">Next</button></div>
            </div>
            <div class="forgot_password forgot_password2 " *ngIf="showResetPwd2 ">
                <div class="logo-container ">
                    <i class="icon-back_arrow back-icon top-arrow" aria-hidden="true " (click)="showResetPasswordSteps(1) "></i>
                    <img src="../../assets/reflow-newlogo.png " class="logo " />
                </div>
                <hr>
                <p class="header-text margin-top-text">Reset your password</p>
                <p class="blue-text sub-header-text">You should have received an email with your code.</p>
                <div class="input_field inputWithIcon ">
                    <input type="text " placeholder="Enter your code here " class="input " [(ngModel)]="resetcode">
                    <i class="icon-lock icons" aria-hidden="true "></i>
                </div>
                <p class="help-text " (click)="sendcode()">Haven’t received a code?</p>
                <div class="center-container "><button type="button " class="btn-login " (click)="showResetPasswordSteps(3) ">Next</button></div>
            </div>
            <div class="forgot_password forgot_password3 " *ngIf="showResetPwd3 ">
                <div class="logo-container ">
                    <i class="icon-back_arrow back-icon top-arrow" aria-hidden="true " (click)="showResetPasswordSteps(2) "></i>
                    <img src="../../assets/reflow-newlogo.png " class="logo " />
                </div>
                <hr>
                <p class="header-text margin-top-text">Reset your password</p>
                <p class="blue-text sub-header-text">Select your new password</p>
                <div class="input_field inputWithIcon">
                    <input type="{{fieldTypePassword}}" placeholder="Password " class="input " [(ngModel)]="passwordForRest">
                    <i class="icon-lock icons" aria-hidden="true "></i>
                    <i class="{{iconTypePassword}} right-icon " aria-hidden="true " (click)="showPasswordText(2)"></i>
                </div>
                <div class="input_field inputWithIcon input_field_login">
                    <input type="{{fieldTypePasswordC}}" placeholder="Repeat Your Password " class="input " [(ngModel)]="confirmPasswordForReset">
                    <i class="icon-lock icons" aria-hidden="true "></i>
                    <i class="{{iconTypeC}} right-icon " aria-hidden="true " (click)="showPasswordText(3)"></i>
                </div>
                <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" [size]="size" [hl]="lang" [theme]="theme" [type]="type" formControlName="recaptcha" (success)="handleSuccess($event)">
                </ngx-recaptcha2>
                <div class="center-container "><button type="button " class="btn-login " (click)="resetPassword()" *ngIf="captchaSuccess">Next</button></div>
            </div>
            <div class="sign_up " style="display: none !important; ">
                <div class="input_field ">
                    <input type="text " placeholder="Username " class="input ">
                </div>
                <div class="input_field ">
                    <input type="text " placeholder="E-mail " class="input ">
                </div>
                <div class="input_field ">
                    <input type="password " placeholder="Password " class="input ">
                    <i class="icon-view right-icon " aria-hidden="true "></i>
                </div>
                <div class="btn "><a href="# ">Sign up</a></div>
            </div>
            <div class="footer ">
                <p class="privacy-socials">
                    <a class="footer-text blue-text privacy-text" href="https://www.re-flow.io/terms-and-conditions/">Privacy Policy Terms</a>
                    <a href="https://www.facebook.com/reflowmaritime "><i class="fa fa-facebook-square " aria-hidden="true "></i></a>
                    <a href="https://www.linkedin.com/company/reflow-maritime "><i class="fa fa-linkedin-square " aria-hidden="true "></i></a>
                </p>
                <p class="footer-text ">V1.18 Copyrights &copy; {{ current_year }} ReFlow ApS - All rights reserved</p>
            </div>
        </div>
    </div>
</section>
<!---signe up ui -->
<section class="core " *ngIf="showsignupstep ">
    <div class="wrapper ">
        <div class="tabs ">
            <ul>
                <li class="sign_in_li " (click)="showLoginSignUpSteps(1) ">LOGIN</li>
                <li class="devider "></li>
                <li class="sign_up_li active " (click)="showLoginSignUpSteps(2) ">SIGN UP</li>
            </ul>
        </div>
        <div class="container ">
            <div class="sign_up_step1 " *ngIf="showSignUp1 ">
                <div class="logo-container ">
                    <i class="icon-back_arrow back-icon" aria-hidden="true "></i>
                    <img src="../../assets/reflow-newlogo.png " class="logo " />
                </div>
                <p class="header-text ">Sign in to your account</p>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="First Name*" class="input signup-inputs " required>
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="Last Name * " class="input signup-inputs " required>
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="E-mail *" class="input signup-inputs " required>
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="password " placeholder="Set a Password* " class="input signup-inputs " required>
                    <i class="icon-view right-icon right-icon-sign-up" aria-hidden="true "></i>
                </div>
                <p class="help-text ">Do you need help?</p>
                <div class="center-container "><button type="button " class="btn-login " (click)="showSignUpSteps(2) ">Next</button></div>
            </div>
            <div class="sign_up_step2 " *ngIf="showSignUp2 ">
                <div class="logo-container ">
                    <i class="icon-back_arrow back-icon" aria-hidden="true "></i>
                    <img src="../../assets/reflow-newlogo.png " class="logo " />
                </div>
                <p class="header-text ">Sign in to your account</p>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="Company Name* " class="input signup-inputs ">
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="Industry* " class="input signup-inputs ">
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="Address* " class="input signup-inputs ">
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="Post Code* " class="input signup-inputs ">
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="City* " class="input signup-inputs ">
                </div>
                <div class="input_field inputWithIcon signup-input_field ">
                    <input type="text " placeholder="Country* " class="input signup-inputs ">
                </div>
                <p class="help-text ">Do you need help?</p>
                <div class="center-container "><button type="button " class="btn-login " (click)="showSignUpSteps(3) ">Next</button></div>
            </div>
            <div class="footer signup-footer ">
                <p class="privacy-socials">
                    <a class="footer-text blue-text ">Privacy policy Terms</a>
                    <a href="https://www.facebook.com/reflowmaritime "><i class="fa fa-facebook-square " aria-hidden="true "></i></a>
                    <a href="https://www.linkedin.com/company/reflow-maritime "><i class="fa fa-linkedin-square " aria-hidden="true "></i></a>
                </p>
                <p class="footer-text ">V1.18 Copyrights &copy; {{ current_year }} ReFlow ApS - All rights reserved</p>
            </div>
        </div>
    </div>
</section>
<!-----------------plans table------------------->
<section class="core-table" *ngIf="showPricingTable ">

    <div class="icons-container">
        <img src="../../assets/basicplanicon.png" class="basic-plan-icon" />
        <img src="../../assets/advancedplanicon.png" class="advanced-plan-icon" />
        <img src="../../assets/enterpriseplanicon.png" class="entreprise-plan-icon" />
        <img src="../../assets/datasubscribericon.png" class="data-subscriber-plan-icon" />
    </div>
    <div class="table-wrapper ">
        <table class="table table-striped">
            <thead>
                <tr class="table-head">
                    <th scope="col"><img src="../../assets/landing-page/Reflow logo W.svg" class="logo " /></th>
                    <th scope="col" class="table-head-title center-content">Basic</th>
                    <th scope="col" class="table-head-title center-content">Advanced</th>
                    <th scope="col" class="table-head-title center-content">Enterprise</th>
                    <th scope="col" class="table-head-title center-content">Data Subscriber</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="table-title">Proof of Assessment</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">Calculation Sharing</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">Buy additional Flowcredits</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check reduction-icon"></em> <span class="reduction-text">-10 %</span></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check reduction-icon"></em><span class="reduction-text">-15 %</span></td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">Engineer Validation</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check reduction-icon"></em><span class="reduction-text">-30 %</span></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check reduction-icon"></em><span class="reduction-text">-30 %</span></td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">Customer Service Chat</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                </tr>
                <tr>
                    <td class="table-title">Export Data</td>
                    <td class="center-content">&#8211;</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">Custom Material Data</td>
                    <td class="center-content">&#8211;</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">API Integration</td>
                    <td class="center-content">&#8211;</td>
                    <td class="center-content">&#8211;</td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                    <td class="center-content"><em class="icon-validation-completed icon-check"></em></td>
                </tr>
                <tr>
                    <td class="table-title pricing-td pricing-text">Pricing per Month</td>
                    <td class="pricing-td center-content">
                        <p class="pricing-text center-content">3500 DDK</p>
                        <p class="pricing-text center-content">470 EUR</p>
                        <p class="pricing-text center-content">560 USD</p>
                    </td>
                    <td class="pricing-td center-content">
                        <p class="pricing-text center-content">5500 DDK</p>
                        <p class="pricing-text center-content">740 EUR</p>
                        <p class="pricing-text center-content">890 USD</p>
                    </td>
                    <td class="pricing-td center-content">
                        <p class="pricing-text center-content">7000 DDK</p>
                        <p class="pricing-text center-content">940 EUR</p>
                        <p class="pricing-text center-content">1120 USD</p>
                    </td>
                    <td class="pricing-td pricing-text center-content">Contact us</td>
                </tr>
                <tr>
                    <td class="table-title">Flowcredits per Month</td>
                    <td class="table-text center-content">8</td>
                    <td class="table-text center-content">16</td>
                    <td class="table-text center-content">24</td>
                    <td class="center-content">&#8211;</td>
                </tr>
                <tr>
                    <td class="table-title">Commitment</td>
                    <td class="table-text center-content">6 months</td>
                    <td class="table-text center-content">12 months</td>
                    <td class="table-text center-content">12 months</td>
                    <td class="table-text center-content">6 months</td>
                </tr>
                <tr>
                    <td class="table-title">Users included</td>
                    <td class="table-text center-content">4</td>
                    <td class="table-text center-content">4</td>
                    <td class="table-text center-content">4</td>
                    <td class="table-text center-content">4</td>
                </tr>
                <tr>
                    <td class="table-title pricing-td">Please select a plan</td>
                    <td> <button href="#" class="checkout-btn">Checkout</button></td>
                    <td> <button href="#" class="checkout-btn">Checkout</button></td>
                    <td> <button href="#" class="checkout-btn">Checkout</button></td>
                    <td> <button href="#" class="checkout-btn">Checkout</button></td>
                </tr>
            </tbody>
        </table>
    </div>

    <p class="buttom-text">99 EUR/112 USD/736 DKK per additional user</p>
</section>
