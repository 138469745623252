import { OnChanges } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Console } from 'console';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Product } from 'src/app/models/product.model';
import { Screening, ScreeningLog } from 'src/app/models/screening.model';
import { ScreeningService } from 'src/app/services/screening.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-screening-setup',
  templateUrl: './screening-setup.component.html',
  styleUrls: ['./screening-setup.component.scss']
})
export class ScreeningSetupComponent implements OnInit, OnChanges {

  @Input() public screeningId: number;
  @Output() close = new EventEmitter();

  public screeningToSetup: FormGroup;
  public screening: Screening;
  public product: Product;
  public remainingText: number = 0;
  public countText: string;
  public submitted: boolean = false;
  public isTyping = false;
  public showAlert = false;
  public canSubmit = false;
  public industries:string[]; 
  public searchText:string="";
  public disable=true;
  public textLength = 0;
  pu

  constructor(
    private fb: FormBuilder,
    private screeningService: ScreeningService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private dbService: NgxIndexedDBService
  ) { }

  ngOnInit(): void {
    this.industries =["Maritime",
      "Chemical",
      "Construction",
      "Food, beverages and related products",
      "Health products",
      "IT products",
      "Utilities",
      "Transportation",
      "Other"]
    this.screeningToSetup = this.fb.group({
      id: 0,
      finished: [this.screening?.finished, Validators.required],
      name: [this.screening?.name, Validators.required],
      industry: [this.screening?.industry],
      creationDate: [this.screening?.creationDate],
      lastUpdate: [this.screening?.lastUpdate],
      state: [this.screening?.state],
      companyId: [this.screening?.companyId],
      product: this.fb.group({
        id: 0,
        name: ["", Validators.required],
        weight: ["", Validators.required],
        type: ["", Validators.required],
        lifespan: ["", Validators.required],
        quantity: ["", ],
        durability: ["", ],
        centralProperties: ["", ],
        comment: ["", ],
      }),
    });

    this.activatedRoute.params.subscribe((params) => {
      let screeningId = Number(params["screeningId"]);
      this.screeningService.getScreeningById(screeningId).subscribe(res => {
        this.screening = res;
        this.disable=this.screening['validated'];
        this.screeningToSetup = this.fb.group({
          id: this.screening.id,
          finished: [this.screening?.finished],
          name: [this.screening?.name],
          industry: [this.screening?.industry],
          creationDate: [this.screening?.creationDate],
          lastUpdate: [this.screening?.lastUpdate],
          state: [this.screening?.state],
          companyId: [this.screening?.companyId],
          product: this.fb.group({
            id: this.screening.product?.id,
            name: [this.screening.product?.name, Validators.required],
            weight: [this.screening.product?.weight, Validators.required],
            type: [this.screening.product?.type, Validators.required],
            lifespan: [this.screening.product?.lifeSpan, Validators.required],
            quantity: [this.screening.product?.quantity],
            durability: [this.screening.product?.durability],
            centralProperties: [this.screening.product?.centralProperties],
            comment: [this.screening.product?.comment],
            screeningId: this.screening.id,
          }),
        });
      });
    });
    this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<Screening[]>) => {
      console.log("*********************************",indexedDBResut);
    });
  }

  ngOnChanges() {

  }

  get frm() {
    return this.screeningToSetup.controls.product['controls'];
  }

  public next() {
    if (this.screeningToSetup.valid == false) {
      this.showAlert = true;
      return;
    }
    else {
      this.screeningService.updateScreeningProduct(this.screeningToSetup.value).subscribe(res => {
        this.dbService.getAll('Screenings').subscribe((indexedDBResut:Array<Screening[]>) => {
          let screenings = indexedDBResut[0];
          screenings[screenings.findIndex(x=>x.id==res.id)]=res;
          this.dbService.clear('Screenings').subscribe(deleteResult=>{
            this.dbService.add('Screenings', screenings).subscribe(addResult=>{
            });
          });
        });
        this.close.emit("next");
        this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user=>{
          let screeningLog = {date:new Date(),description:"Update Screening Setup",screeningId:this.screeningId,userName:user.fullName};
          this.screeningService.createScreeningLog(screeningLog).subscribe(resLog=>{
          });
        });
      });
    }
  }
 

  public save(): void {
    this.screeningService.updateScreeningProduct(this.screeningToSetup.value).subscribe(res => {
      console.log("///////////////////////////",res);
      this.close.emit("close");
      this.userService.getUserById(Number(localStorage.getItem("user_id"))).subscribe(user=>{
        let screeningLog = {date:new Date(),description:"Update Screening Setup",screeningId:this.screeningId,userName:user.fullName };
        this.screeningService.createScreeningLog(screeningLog).subscribe(resLog=>{
        });
      });
    });
  }

  public selectIndustry(industryToChange:any):void{
    this.screeningToSetup.controls.industry.setValue(industryToChange);
  }


}
